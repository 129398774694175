<template>
  <div class="card">
    <div class="card-header pt-1 pb-1 bg-frontera text-white">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-10">
            <h5 class="mb-0 p-2">
              Detalle Proyección Transporte Internos Fluidos
            </h5>
          </div>
          <div class="col-md-2 pt-3 pb-3">
            <div class="btn-group float-right">
              <button
                type="button"
                data-card-widget="collapse"
                data-toggle="tooltip"
                title="Collapse"
                class="btn btn-tool"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <div class="btn-group float-right">
            <button
              class="btn bg-info shadow"
              @click="saveDetalle()"
              v-if="
                $store.getters.can('tif.proyecciones.saveDetalleTif') &&
                  this.proyecciones &&
                  this.proyecciones.length > 0
              "
              :disabled="
                $parent.form.estado == 2 ||
                  [1, 2].includes(
                    $parent.$refs.tifProyeccionPlantaExterna.visualizar_viaje
                  )
              "
            >
              <i class="fas fa-sync-alt fa-spin"></i> Guardar Detalles
            </button>
            <button
              type="button"
              class="btn bg-primary ml-1 text-white shadow"
              id="agregarProyeccion"
              @click="addProyeccion()"
              :disabled="
                [1, 2].includes(visualizar_proyeccion) ||
                  $parent.form.estado == 2 ||
                  ($parent.$refs.tifProyeccionPlantaExterna &&
                    [1, 2].includes(
                      $parent.$refs.tifProyeccionPlantaExterna.visualizar_viaje
                    ))
              "
              style="cursor: pointer"
            >
              <i class="fas fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="row mb-3">
        <div class="table-responsive mt-2">
          <div class="col-md-12">
            <table
              class="table table-bordered table-striped table-hover table-sm"
              style="font-size: 90%;"
            >
              <thead class="bg-dark">
                <tr class="text-center">
                  <th rowspan="2">Acción</th>
                  <th rowspan="2" style="min-width: 15em;">
                    Operación
                  </th>
                  <th rowspan="2" style="min-width: 15em;">
                    Producto
                  </th>
                  <th rowspan="2" style="min-width: 15em;">
                    Tipo Vehículo
                  </th>
                  <th rowspan="2" style="min-width: 15em;">
                    Tipo Tarifa
                  </th>
                  <th rowspan="2" style="min-width: 15em;">
                    Turno
                  </th>
                  <th rowspan="2" style="min-width: 15em;">
                    Empresa
                  </th>
                  <th rowspan="2" style="min-width: 10em;">
                    Cant Vehículos
                  </th>
                  <th rowspan="2" style="min-width: 10em;">
                    Servicio T.O.V <small>(BLS)</small>
                  </th>
                  <th rowspan="2" style="min-width: 10em;">
                    Galones
                  </th>
                  <th colspan="3">Operativo</th>
                  <th colspan="2">Disponibilidad</th>
                  <th colspan="2">Kilómetros</th>
                  <th colspan="3">Total</th>
                </tr>
                <tr class="text-center">
                  <th style="min-width: 10em;">Cantidad</th>
                  <th style="min-width: 10em;">Valor</th>
                  <th style="min-width: 10em;">
                    Costo Tarifa Fija
                  </th>
                  <th style="min-width: 10em;">Cantidad</th>
                  <th style="min-width: 10em;">Valor</th>
                  <th style="min-width: 8em;">Cantidad</th>
                  <th style="min-width: 10em;">
                    Costo Tarifa Variable
                  </th>
                  <th style="min-width: 8em;">Cantidad</th>
                  <th style="min-width: 8em;">Valor</th>
                </tr>
              </thead>
              <tbody>
                <!-- Dato Existentes / Editar Datos  -->
                <tr v-for="(proyec, index) in proyecciones" :key="index">
                  <!-- Campos Botones -->
                  <td class="text-center">
                    <div class="btn-group" v-if="visualizar_proyeccion != 2">
                      <button
                        type="button"
                        class="btn bg-navy"
                        :disabled="
                          visualizar_proyeccion != -1 ||
                            ($parent.$refs.tifProyeccionPlantaExterna &&
                              [1, 2].includes(
                                $parent.$refs.tifProyeccionPlantaExterna
                                  .visualizar_viaje
                              ))
                        "
                        @click="editProyeccion(index)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn bg-danger"
                        :disabled="
                          visualizar_proyeccion != -1 ||
                            ($parent.$refs.tifProyeccionPlantaExterna &&
                              [1, 2].includes(
                                $parent.$refs.tifProyeccionPlantaExterna
                                  .visualizar_viaje
                              ))
                        "
                        @click="destroyProyeccion(index)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <div
                      class="btn-group"
                      v-if="
                        visualizar_proyeccion == 2 &&
                          indice_actualizar_proyeccion == index
                      "
                    >
                      <button
                        type="button"
                        class="btn bg-success"
                        v-show="!$v.proyeccion.$invalid"
                        @click="saveUpdateProyeccion(index)"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button class="btn bg-danger" @click="cancelProyeccion()">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                    <div
                      class="btn-group"
                      v-if="
                        visualizar_proyeccion == 2 &&
                          indice_actualizar_proyeccion !== index
                      "
                    >
                      <button
                        type="button"
                        class="btn bg-navy"
                        disabled
                        @click="editProyeccion(index)"
                      >
                        <i class="fas fa-edit"></i>
                      </button>
                      <button
                        class="btn bg-danger"
                        disabled
                        @click="destroyProyeccion(index)"
                      >
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  <!-- Campo Operación -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <v-select
                        placeholder="Operación"
                        label="Noperacion"
                        :options="listasForms.operaciones"
                        v-model="operacion"
                        :class="[
                          $v.proyeccion.operacion_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          'form-control form-control-sm p-0',
                        ]"
                        @input="selectOperacion()"
                      ></v-select>
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.operacion.Noperacion }}
                    </div>
                  </td>
                  <!-- Campo Producto -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <v-select
                        placeholder="Producto"
                        label="nombre"
                        :options="listasForms.productos"
                        v-model="producto"
                        :class="[
                          $v.proyeccion.producto_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          'form-control form-control-sm p-0',
                        ]"
                        @input="selectProducto()"
                      ></v-select>
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.producto.nombre }}
                    </div>
                  </td>
                  <!-- Campo Tipo Vehículo -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <v-select
                        placeholder="Tipo Vehículo"
                        label="nombre"
                        :options="listasForms.tipo_vehiculos"
                        v-model="tipo_vehiculo"
                        :class="[
                          $v.proyeccion.tipo_vehiculo_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          'form-control form-control-sm p-0',
                        ]"
                        @input="selectTipoVehiculo()"
                      ></v-select>
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.tipo_vehiculo.nombre }}
                    </div>
                  </td>
                  <!-- Campo Tipo Tarifa -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <select
                        class="form-control form-control-sm"
                        v-model="proyeccion.tipo_tarifa"
                        :class="
                          $v.proyeccion.tipo_tarifa.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="buscarTarifaUni()"
                      >
                        <option value="">Seleccione...</option>
                        <option
                          v-for="tarifa in listasForms.tipo_tarifas"
                          :key="tarifa.numeracion"
                          :value="tarifa.numeracion"
                        >
                          {{ tarifa.descripcion }}
                        </option>
                      </select>
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.nTarifa }}
                    </div>
                  </td>
                  <!-- Campo Turno -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <v-select
                        placeholder="Turno"
                        label="nombre"
                        :options="listasForms.turnos"
                        v-model="turno"
                        :class="[
                          $v.proyeccion.turno_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          'form-control form-control-sm p-0',
                        ]"
                        @input="selectTurno()"
                      ></v-select>
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.turno.nombre }}
                    </div>
                  </td>
                  <!-- Campo Empresa -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <v-select
                        placeholder="Empresa"
                        label="razon_social"
                        :options="listasForms.empresas"
                        v-model="empresa"
                        :class="[
                          $v.proyeccion.empresa_id.$invalid
                            ? 'is-invalid'
                            : 'is-valid',
                          $store.getters.getDarkMode ? 'dark-vselect' : '',
                          'form-control form-control-sm p-0',
                        ]"
                        @input="selectEmpresa()"
                      ></v-select>
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.empresa.razon_social }}
                    </div>
                  </td>
                  <!-- Campo Cantidad Vehículo -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <input
                        type="number"
                        class="form-control form-control-sm"
                        v-model="proyeccion.cant_vh"
                        :class="
                          $v.proyeccion.cant_vh.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                      />
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.cant_vh }}
                    </div>
                  </td>
                  <!-- Campo Servicio Galones -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="proyeccion.barriles_servicio"
                        :class="
                          $v.proyeccion.barriles_servicio.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="calcGalones()"
                      />
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.barriles_servicio }}
                    </div>
                  </td>
                  <!-- Campo Galones -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.galones }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.galones }}
                    </div>
                  </td>
                  <!-- Campo Cantidad Operativa -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="proyeccion.cant_operativo"
                        :class="
                          $v.proyeccion.cant_operativo.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="[totalesUni(), calcCant()]"
                      />
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.cant_operativo }}
                    </div>
                  </td>
                  <!-- Campo Tarifa -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.valor_operativo_format }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.valor_operativo_format }}
                    </div>
                  </td>
                  <!-- Campo Valor fijo -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.valor_fijo_format }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.valor_fijo_format }}
                    </div>
                  </td>
                  <!-- Campo Cantidad Disponible -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2 &&
                          proyeccion.tipo_tarifa == 3
                      "
                    >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="proyeccion.cant_disponible"
                        :class="
                          $v.proyeccion.cant_disponible.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="[totalesUni(), calcCant()]"
                      />
                    </div>
                    <div v-else class="text-center">
                      <span
                        class="badge bg-warning"
                        v-if="!proyec.cant_disponible"
                      >
                        No cumple</span
                      >
                      {{ proyec.cant_disponible }}
                    </div>
                  </td>
                  <!-- Campo Valor Disponible -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.valor_disponible_format }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.valor_disponible_format }}
                    </div>
                  </td>
                  <!-- Campo Cantidad Km -->
                  <td>
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2 &&
                          proyeccion.tipo_tarifa == 2
                      "
                    >
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="proyeccion.cant_km"
                        :class="
                          $v.proyeccion.cant_km.$invalid
                            ? 'is-invalid'
                            : 'is-valid'
                        "
                        @input="[totalesUni(), calcCant()]"
                      />
                    </div>
                    <div v-else class="text-center">
                      <span class="badge bg-warning" v-if="!proyec.cant_km">
                        No cumple</span
                      >
                      {{ proyec.cant_km }}
                    </div>
                  </td>
                  <!-- Campo Tarifa Km -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.valor_km_format }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.valor_km_format }}
                    </div>
                  </td>
                  <!-- Campo Cantidad -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.total_cant }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.total_cant }}
                    </div>
                  </td>
                  <!-- Campo Total -->
                  <td class="text-center">
                    <div
                      v-if="
                        indice_actualizar_proyeccion === index &&
                          visualizar_proyeccion === 2
                      "
                    >
                      {{ proyeccion.total_format }}
                    </div>
                    <div v-else class="text-center">
                      {{ proyec.total_format }}
                    </div>
                  </td>
                </tr>
                <!-- Crear Datos -->
                <tr v-if="visualizar_proyeccion === 1">
                  <!-- Campo Botones -->
                  <td>
                    <div class="btn-group float-right">
                      <button
                        class="btn bg-success"
                        v-show="!$v.proyeccion.$invalid"
                        @click="saveProyeccion()"
                      >
                        <i class="fas fa-save"></i>
                      </button>
                      <button class="btn bg-danger" @click="cancelProyeccion()">
                        <i class="fas fa-trash"></i>
                      </button>
                    </div>
                  </td>
                  <!-- Campo Operación -->
                  <td>
                    <v-select
                      placeholder="Operación"
                      label="Noperacion"
                      :options="listasForms.operaciones"
                      v-model="operacion"
                      :class="[
                        $v.proyeccion.operacion_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectOperacion()"
                    ></v-select>
                  </td>
                  <!-- Campo Producto -->
                  <td>
                    <v-select
                      v-if="
                        proyeccion.operacion_id &&
                          listasForms.productos &&
                          listasForms.productos.length > 0
                      "
                      placeholder="Producto"
                      label="nombre"
                      :options="listasForms.productos"
                      v-model="producto"
                      :class="[
                        $v.proyeccion.producto_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectProducto()"
                    ></v-select>
                    <span class="badge bg-danger" v-else
                      >No hay Productos para la operación seleccionada.</span
                    >
                  </td>
                  <!-- Campo Tipo Vehículo -->
                  <td>
                    <v-select
                      placeholder="Tipo Vehículo"
                      label="nombre"
                      :options="listasForms.tipo_vehiculos"
                      v-model="tipo_vehiculo"
                      :class="[
                        $v.proyeccion.tipo_vehiculo_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectTipoVehiculo()"
                    ></v-select>
                  </td>
                  <!-- Campo Tipo Tarifa -->
                  <td>
                    <select
                      class="form-control form-control-sm"
                      v-model="proyeccion.tipo_tarifa"
                      :class="
                        $v.proyeccion.tipo_tarifa.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="buscarTarifaUni()"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tarifa in listasForms.tipo_tarifas"
                        :key="tarifa.numeracion"
                        :value="tarifa.numeracion"
                      >
                        {{ tarifa.descripcion }}
                      </option>
                    </select>
                  </td>
                  <!-- Campo Turno -->
                  <td>
                    <v-select
                      placeholder="Turno"
                      label="nombre"
                      :options="listasForms.turnos"
                      v-model="turno"
                      :class="[
                        $v.proyeccion.turno_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectTurno()"
                    ></v-select>
                  </td>
                  <!-- Campo Empresa -->
                  <td>
                    <v-select
                      placeholder="Empresa"
                      label="razon_social"
                      :options="listasForms.empresas"
                      v-model="empresa"
                      :class="[
                        $v.proyeccion.empresa_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        $store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ]"
                      @input="selectEmpresa()"
                    ></v-select>
                  </td>
                  <!-- Campo Cantidad Vehículo -->
                  <td>
                    <input
                      type="number"
                      class="form-control form-control-sm"
                      v-model="proyeccion.cant_vh"
                      :class="
                        $v.proyeccion.cant_vh.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    />
                  </td>
                  <!-- Campo Servicio Galones -->
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="proyeccion.barriles_servicio"
                      :class="
                        $v.proyeccion.barriles_servicio.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="calcGalones()"
                    />
                  </td>
                  <!-- Campo Galones -->
                  <td class="text-center">
                    {{ proyeccion.galones }}
                  </td>
                  <!-- Campo Cantidad Operativa -->
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="proyeccion.cant_operativo"
                      :class="
                        $v.proyeccion.cant_operativo.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      @input="[totalesUni(), calcCant()]"
                    />
                  </td>
                  <!-- Campo Valor Operativo -->
                  <td class="text-center" v-if="proyeccion.tipo_tarifa == 3">
                    {{ proyeccion.valor_operativo_format }}
                  </td>
                  <td class="text-center" v-else>
                    <span class="badge bg-warning">No Cumple</span>
                  </td>
                  <!-- Campo Costo Tarifa Fija -->
                  <td class="text-center" v-if="proyeccion.tipo_tarifa == 2">
                    {{ proyeccion.valor_fijo_format }}
                  </td>
                  <td class="text-center" v-else>
                    <span class="badge bg-warning">No Cumple</span>
                  </td>
                  <!-- Campo Cantidad Disponible -->
                  <td class="text-center">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="proyeccion.cant_disponible"
                      :class="
                        $v.proyeccion.cant_operativo.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-if="proyeccion.tipo_tarifa == 3"
                      @input="[totalesUni(), calcCant()]"
                    />
                  </td>
                  <!-- Campo Valor Disponible -->
                  <td class="text-center" v-if="proyeccion.tipo_tarifa == 3">
                    {{ proyeccion.valor_disponible_format }}
                  </td>
                  <td v-else class="text-center">
                    <span class="badge bg-warning">No Cumple </span>
                  </td>
                  <!-- Campo Cantidad Km -->
                  <td>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="proyeccion.cant_km"
                      :class="
                        $v.proyeccion.cant_km.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      v-if="proyeccion.tipo_tarifa == 2"
                      @input="[totalesUni(), calcCant()]"
                    />
                  </td>
                  <!-- Campo Tarifa Km -->
                  <td class="text-center" v-if="proyeccion.tipo_tarifa == 2">
                    {{ proyeccion.valor_km_format }}
                  </td>
                  <td class="text-center" v-else>
                    <span class="badge bg-warning"> No Cumple</span>
                  </td>
                  <!-- Campo Fracción -->
                  <td class="text-center">
                    {{ proyeccion.total_cant }}
                  </td>
                  <!-- Campo Total -->
                  <td class="text-center">
                    {{ proyeccion.total_format }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "TifProyeccionTransporteInterno",
  components: {
    vSelect,
  },

  data() {
    return {
      proyecciones: [],
      vehiculo_id: null,
      turno_id: null,
      tipo_vehiculo_id: null,
      liqui: {},
      listasForms: {},
      visualizar_proyeccion: -1,
      indice_actualizar_proyeccion: -1,
      turno: {},
      empresa: {},
      operacion: {},
      producto: {},
      tipo_vehiculo: {},
      proyeccion: {
        id: null,
        operacion: null,
        operacion_id: null,
        producto: null,
        producto_id: null,
        tipo_vehiculo: null,
        tipo_vehiculo_id: null,
        tipo_tarifa: null,
        turno: null,
        turno_id: null,
        empresa: null,
        empresa_id: null,
        cant_vh: null,
        barriles_servicio: null,
        galones: null,
        cant_operativo: null,
        cant_disponible: null,
        cant_km: null,
        valor_operativo: null,
        valor_disponible: null,
        valor_km: null,
        valor_fijo: null,
        valor_operativo_format: null,
        valor_disponible_format: null,
        valor_km_format: null,
        valor_fijo_format: null,
        valor_total: null,
        total_format: null,
        total_cant: null,
      },
    };
  },

  validations() {
    let cant_operativo = {};
    let cant_disponible = {};
    let cant_km = {};
    let valor_operativo = {};
    let valor_disponible = {};
    let valor_km = {};
    let valor_fijo = {};

    if (this.proyeccion.tipo_tarifa == 2) {
      cant_operativo = {
        required,
      };
      cant_km = {
        required,
      };
      cant_disponible = {
        required: false,
      };
      valor_km = {
        required,
      };
      valor_fijo = {
        required,
      };
      valor_operativo = {
        required: false,
      };
      valor_disponible = {
        required: false,
      };
    } else {
      cant_operativo = {
        required,
      };
      cant_km = {
        required: false,
      };
      cant_disponible = {
        required,
      };
      valor_km = {
        required: false,
      };
      valor_fijo = {
        required: false,
      };
      valor_operativo = {
        required,
      };
      valor_disponible = {
        required,
      };
    }

    return {
      proyeccion: {
        operacion_id: {
          required,
        },
        producto_id: {
          required,
        },
        tipo_vehiculo_id: {
          required,
        },
        tipo_tarifa: {
          required,
        },
        turno_id: {
          required,
        },
        empresa_id: {
          required,
        },
        cant_vh: {
          required,
        },
        barriles_servicio: {
          required,
        },
        galones: {
          required,
        },
        cant_operativo: cant_operativo,
        cant_km: cant_km,
        cant_disponible: cant_disponible,
        valor_operativo: valor_operativo,
        valor_disponible: valor_disponible,
        valor_km: valor_km,
        valor_fijo: valor_fijo,
      },
    };
  },

  methods: {
    async addProyeccion() {
      await this.limpiarDatos();
      this.proyeccion.proyecciones_tif_planta_externa_id = this.$parent.form.id;
      this.visualizar_proyeccion = 1;
    },

    async saveProyeccion() {
      this.proyecciones.push(this.proyeccion);
      this.visualizar_proyeccion = -1;
    },

    async cancelProyeccion() {
      await this.limpiarDatos();
      this.visualizar_proyeccion = -1;
    },

    async saveUpdateProyeccion(index) {
      this.proyecciones[index] = this.proyeccion;
      this.visualizar_proyeccion = -1;
    },

    editProyeccion(index) {
      this.indice_actualizar_proyeccion = index;
      this.visualizar_proyeccion = 2;
      this.operacion = this.proyecciones[index].operacion;
      this.producto = this.proyecciones[index].producto;
      this.tipo_vehiculo = this.proyecciones[index].tipo_vehiculo;
      this.turno = this.proyecciones[index].turno;
      this.empresa = this.proyecciones[index].empresa;
      this.proyeccion = { ...this.proyecciones[index] };
    },

    destroyProyeccion(index) {
      this.proyecciones.splice(index, 1);
    },

    calcGalones() {
      this.proyeccion.galones = this.proyeccion.barriles_servicio
        ? parseFloat(this.proyeccion.barriles_servicio) * 42
        : null;
    },

    calcCant() {
      this.proyeccion.total_cant =
        (this.proyeccion.cant_operativo && this.proyeccion.cant_km) ||
        (this.proyeccion.cant_operativo && this.proyeccion.cant_disponible)
          ? this.proyeccion.tipo_tarifa == 2
            ? parseFloat(this.proyeccion.cant_operativo) +
              parseFloat(this.proyeccion.cant_km)
            : parseFloat(this.proyeccion.cant_operativo) +
              parseFloat(this.proyeccion.cant_disponible)
          : null;
    },

    async saveDetalle() {
      let me = this;
      me.cargando = true;
      await axios({
        method: "PUT",
        url: "/api/tif/proyecciones/saveDetalleTif",
        data: {
          proyecciones_tif_planta_externa_id: this.$parent.form.id,
          proyecciones: this.proyecciones,
        },
      }).then(async (response) => {
        me.cargando = false;
        me.$route.params.data_edit = await response.data;
        await this.$swal({
          icon: "success",
          title: "Se guardó el detalle planta externa exitosamente...",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      });
      me.cargando = false;
    },

    async limpiarDatos() {
      this.proyeccion = {
        id: null,
        operacion: null,
        operacion_id: null,
        producto: null,
        producto_id: null,
        tipo_vehiculo: null,
        tipo_vehiculo_id: null,
        turno: null,
        turno_id: null,
        empresa: null,
        empresa_id: null,
        cant_vh: null,
        barriles_servicio: null,
        galones: null,
        cant_operativo: null,
        cant_km: null,
        valor_operativo: null,
        valor_operativo_format: null,
        valor_disponible: null,
        valor_disponible_format: null,
        valor_km: null,
        valor_km_format: null,
        valor_fijo: null,
        valor_fijo_format: null,
        valor_total: null,
        total_format: null,
      };
      this.operacion = {};
      this.producto = {};
      this.tipo_vehiculo = {};
      this.turno = {};
      this.empresa = {};
    },

    async getOperacion() {
      let me = this;
      await axios
        .get("/api/tif/operaciones/lista", {
          params: {
            bloque_id: me.$parent.form.bloque_id,
          },
        })
        .then((response) => {
          me.listasForms.operaciones = response.data;
        });
    },

    async getTipoVehiculo() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      await axios
        .get(url, {
          params: {
            linea_negocio_id: 3,
          },
        })
        .then(function(response) {
          me.listasForms.tipo_vehiculos = response.data;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTurnos() {
      const filtros = {
        linea_negocio_id: [3],
        estado: 1,
      };
      await axios
        .get("/api/admin/turnos/lista", {
          params: filtros,
        })
        .then((response) => {
          this.listasForms.turnos = response.data;
        });
    },

    async getEmpresas() {
      await axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: [3],
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    async getProductos() {
      this.listasForms.productos = [];
      if (this.proyeccion.operacion_id) {
        let me = this;
        await axios({
          method: "GET",
          url: "/api/tif/solicitudesVehiculos/operacionProducto",
          params: me.proyeccion.operacion_id,
        }).then(async (response) => {
          if (response.data.productos == undefined) {
            await this.$swal({
              title: "Advertencia !",
              text:
                "No se encontraron productos para la operación " +
                this.operacion.Noperacion +
                "!",
              icon: "warning",
              confirmButtonText: "Aceptar!",
              allowOutsideClick: false,
            });
          } else {
            me.listasForms.productos = response.data.productos;
          }
        });
      }
    },

    async getTipoTarifa() {
      await axios.get("/api/lista/96").then((response) => {
        this.listasForms.tipo_tarifas = response.data;
      });
    },

    async selectOperacion() {
      this.proyeccion.operacion_id = null;
      if (this.operacion) {
        this.proyeccion.operacion_id = this.operacion.descripcion;
        this.proyeccion.operacion = {
          descripcion: this.operacion.descripcion,
          Noperacion: this.operacion.Noperacion,
        };
        await this.getProductos();
        await this.buscarTarifaUni();
      }
    },

    async selectProducto() {
      this.proyeccion.producto_id = null;
      if (this.producto) {
        this.proyeccion.producto_id = this.producto.id;
        this.proyeccion.producto = {
          id: this.producto.id,
          nombre: this.producto.nombre,
        };
        await this.buscarTarifaUni();
      }
    },

    async selectTipoVehiculo() {
      this.proyeccion.tipo_vehiculo_id = null;
      if (this.tipo_vehiculo) {
        this.proyeccion.tipo_vehiculo_id = this.tipo_vehiculo.id;
        this.proyeccion.tipo_vehiculo = {
          id: this.tipo_vehiculo.id,
          nombre: this.tipo_vehiculo.nombre,
        };
        await this.buscarTarifaUni();
      }
    },

    async selectTurno() {
      this.proyeccion.turno_id = null;
      if (this.turno) {
        this.proyeccion.turno_id = this.turno.id;
        this.proyeccion.turno = {
          id: this.turno.id,
          nombre: this.turno.nombre,
        };
        await this.buscarTarifaUni();
      }
    },

    async selectEmpresa() {
      this.proyeccion.empresa_id = null;
      if (this.empresa) {
        this.proyeccion.empresa_id = this.empresa.id;
        this.proyeccion.empresa = {
          id: this.empresa.id,
          razon_social: this.empresa.razon_social,
        };
        await this.buscarTarifaUni();
      }
    },

    async buscarTarifaUni() {
      let data = {
        operacion_id: this.proyeccion.operacion_id,
        producto_id: this.proyeccion.producto_id,
        tipo_vehiculo_id: this.proyeccion.tipo_vehiculo_id,
        tipo_tarifa: this.proyeccion.tipo_tarifa,
        turno_id: this.proyeccion.turno_id,
        empresa_id: this.proyeccion.empresa_id,
        bloque_id: this.$parent.form.bloque_id,
        mes: this.$parent.form.mes,
        ano: this.$parent.form.ano,
      };

      this.valor_operativo = null;
      this.valor_disponible = null;
      this.valor_km = null;
      this.valor_fijo = null;
      this.total_format = null;
      this.valor_operativo_format = null;
      this.valor_disponible_format = null;
      this.valor_km_format = null;
      this.valor_fijo_format = null;
      this.valor_total = null;
      this.total_cant = null;

      if (
        this.proyeccion.operacion_id &&
        this.proyeccion.producto_id &&
        this.proyeccion.tipo_vehiculo_id &&
        this.proyeccion.tipo_tarifa &&
        this.proyeccion.turno_id &&
        this.proyeccion.empresa_id
      ) {
        this.cargando = true;
        await axios
          .get("/api/tif/proyecciones/buscarTarifa", {
            params: data,
          })
          .then(async (response) => {
            this.cargando = false;

            if (
              response.data.detTarifa == null ||
              response.data.detTarifa == undefined
            ) {
              await this.$swal({
                title: "Advertencia !",
                text: "No se encontro ninguna tarifa, por favor validar.!",
                icon: "warning",
                confirmButtonText: "Aceptar!",
                allowOutsideClick: false,
              });
              this.cargando = false;
            } else {
              this.proyeccion.valor_operativo = response.data.valor_op
                ? response.data.valor_op
                : null;
              this.proyeccion.valor_disponible = response.data.valor_disponible
                ? response.data.valor_disponible
                : null;
              this.proyeccion.valor_km = response.data.costo_variable
                ? response.data.costo_variable
                : null;
              this.proyeccion.valor_fijo = response.data.costo_fijo
                ? response.data.costo_fijo
                : null;
              this.proyeccion.valor_total = null;

              this.proyeccion.valor_operativo_format = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                response.data.valor_op
              );

              this.proyeccion.valor_disponible_format = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                response.data.valor_disp
              );

              this.proyeccion.valor_fijo_format = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                response.data.costo_fijo
              );

              this.proyeccion.valor_km_format = this.formatCurrency(
                "es-CO",
                "COP",
                2,
                response.data.costo_variable
              );
            }
            this.cargando = false;
          });
        await this.totalesUni();
      }
      this.cargando = false;
    },

    async totalesUni() {
      if (
        (this.proyeccion.cant_operativo &&
          this.proyeccion.valor_fijo &&
          this.proyeccion.cant_km &&
          this.proyeccion.valor_km) ||
        (this.proyeccion.cant_operativo &&
          this.proyeccion.valor_operativo &&
          this.proyeccion.cant_disponible &&
          this.proyeccion.valor_disponible)
      ) {
        if (this.proyeccion.tipo_tarifa == 2) {
          this.proyeccion.valor_total =
            parseFloat(this.proyeccion.cant_operativo) *
              parseFloat(this.proyeccion.valor_fijo) +
            parseFloat(this.proyeccion.cant_km) *
              parseFloat(this.proyeccion.valor_km);

          this.proyeccion.total_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyeccion.valor_total
          );
        } else if (this.proyeccion.tipo_tarifa == 3) {
          this.proyeccion.valor_total =
            parseFloat(this.proyeccion.cant_operativo) *
              parseFloat(this.proyeccion.valor_operativo) +
            parseFloat(this.proyeccion.cant_disponible) *
              parseFloat(this.proyeccion.valor_disponible);

          this.proyeccion.total_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyeccion.valor_total
          );
        }
      }
    },

    async dataDetalle() {
      await this.getOperacion();
      await this.getTipoVehiculo();
      await this.getTurnos();
      await this.getEmpresas();
      await this.getTipoTarifa();
      this.proyecciones =
        this.proyecciones && this.proyecciones.length > 0
          ? this.proyecciones
          : this.$parent.form.tif_det_proyecciones;
      if (this.proyecciones && this.proyecciones.length > 0) {
        for (let i = 0; i < this.proyecciones.length; i++) {
          this.proyecciones[i].valor_operativo_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyecciones[i].valor_operativo
          );

          this.proyecciones[i].valor_disponible_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyecciones[i].valor_disponible
          );

          this.proyecciones[i].valor_fijo_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyecciones[i].valor_fijo
          );

          this.proyecciones[i].valor_km_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyecciones[i].valor_km
          );

          this.proyecciones[i].total_format = this.formatCurrency(
            "es-CO",
            "COP",
            2,
            this.proyecciones[i].valor_total
          );
        }
      }
    },

    async getLiquidaciones(liquidaciones) {
      this.proyecciones = [];
      liquidaciones.forEach((det) => {
        let valor_total = null;

        if (det.liquidacion.tipo_tarifa == 2) {
          valor_total =
            parseFloat(det.cant_operativo) *
              parseFloat(det.tif_det_tarifa_tiempo.costo_fijo) +
            parseFloat(det.tot_km) *
              parseFloat(det.tif_det_tarifa_tiempo.costo_variable);
        } else if (det.liquidacion.tipo_tarifa == 3) {
          valor_total =
            parseFloat(det.cant_operativo) *
              parseFloat(det.tif_det_tarifa_tiempo.valor_op) +
            parseFloat(det.cant_disponible) *
              parseFloat(det.tif_det_tarifa_tiempo.valor_disp);
        }

        this.proyecciones.push({
          id: null,
          proyecciones_tif_planta_externa_id: this.$route.params.data_edit.id,
          operacion: det.liquidacion.tif_operacion,
          operacion_id: det.liquidacion.tif_operacion_id,
          producto: det.liquidacion.producto,
          producto_id: det.liquidacion.producto_id,
          tipo_vehiculo: det.tipo_vehiculo,
          tipo_vehiculo_id: det.tipo_vehiculo_id,
          nTarifa: det.liquidacion.nTarifa,
          tipo_tarifa: det.liquidacion.tipo_tarifa,
          turno: det.turno,
          turno_id: det.turno_id,
          empresa: det.liquidacion.empresa,
          empresa_id: det.liquidacion.empresa_id,
          barriles_servicio: det.liquidacion.volumen,
          galones: parseFloat(det.liquidacion.volumen) * 42,
          cant_operativo: det.cant_operativo,
          cant_disponible: det.cant_disponible,
          cant_vh: det.cant_vh,
          cant_km: det.tot_km,
          valor_operativo: det.tif_det_tarifa_tiempo.valor_op,
          valor_disponible: det.tif_det_tarifa_tiempo.valor_disp,
          valor_km: det.tif_det_tarifa_tiempo.costo_variable,
          valor_fijo: det.tif_det_tarifa_tiempo.costo_fijo,
          valor_operativo_format: null,
          valor_disponible_format: null,
          valor_km_format: null,
          valor_fijo_format: null,
          valor_total: valor_total,
          total_format: null,
          total_cant:
            parseFloat(det.cant_operativo != null ? det.cant_operativo : 0) +
            parseFloat(det.cant_disponible != null ? det.cant_disponible : 0) +
            parseFloat(det.tot_km != null ? det.tot_km : 0),
        });
      });
      await this.dataDetalle();
    },

    formatCurrency(locales, currency, fractionDigits, number) {
      var formato = new Intl.NumberFormat(locales, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: fractionDigits,
      }).format(number);

      return formato;
    },
  },
};
</script>
