var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"}),_c('div',{staticClass:"col-md-2"},[_c('div',{staticClass:"btn-group float-right"},[(
              _vm.$store.getters.can('tif.proyecciones.saveDetalleTif') &&
                this.proyecciones &&
                this.proyecciones.length > 0
            )?_c('button',{staticClass:"btn bg-info shadow",attrs:{"disabled":_vm.$parent.form.estado == 2 ||
                [1, 2].includes(
                  _vm.$parent.$refs.tifProyeccionPlantaExterna.visualizar_viaje
                )},on:{"click":function($event){return _vm.saveDetalle()}}},[_c('i',{staticClass:"fas fa-sync-alt fa-spin"}),_vm._v(" Guardar Detalles ")]):_vm._e(),_c('button',{staticClass:"btn bg-primary ml-1 text-white shadow",staticStyle:{"cursor":"pointer"},attrs:{"type":"button","id":"agregarProyeccion","disabled":[1, 2].includes(_vm.visualizar_proyeccion) ||
                _vm.$parent.form.estado == 2 ||
                (_vm.$parent.$refs.tifProyeccionPlantaExterna &&
                  [1, 2].includes(
                    _vm.$parent.$refs.tifProyeccionPlantaExterna.visualizar_viaje
                  ))},on:{"click":function($event){return _vm.addProyeccion()}}},[_c('i',{staticClass:"fas fa-plus"})])])])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"table-responsive mt-2"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm",staticStyle:{"font-size":"90%"}},[_vm._m(1),_c('tbody',[_vm._l((_vm.proyecciones),function(proyec,index){return _c('tr',{key:index},[_c('td',{staticClass:"text-center"},[(_vm.visualizar_proyeccion != 2)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.visualizar_proyeccion != -1 ||
                          (_vm.$parent.$refs.tifProyeccionPlantaExterna &&
                            [1, 2].includes(
                              _vm.$parent.$refs.tifProyeccionPlantaExterna
                                .visualizar_viaje
                            ))},on:{"click":function($event){return _vm.editProyeccion(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":_vm.visualizar_proyeccion != -1 ||
                          (_vm.$parent.$refs.tifProyeccionPlantaExterna &&
                            [1, 2].includes(
                              _vm.$parent.$refs.tifProyeccionPlantaExterna
                                .visualizar_viaje
                            ))},on:{"click":function($event){return _vm.destroyProyeccion(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                      _vm.visualizar_proyeccion == 2 &&
                        _vm.indice_actualizar_proyeccion == index
                    )?_c('div',{staticClass:"btn-group"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.proyeccion.$invalid),expression:"!$v.proyeccion.$invalid"}],staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveUpdateProyeccion(index)}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelProyeccion()}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                      _vm.visualizar_proyeccion == 2 &&
                        _vm.indice_actualizar_proyeccion !== index
                    )?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":""},on:{"click":function($event){return _vm.editProyeccion(index)}}},[_c('i',{staticClass:"fas fa-edit"})]),_c('button',{staticClass:"btn bg-danger",attrs:{"disabled":""},on:{"click":function($event){return _vm.destroyProyeccion(index)}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e()]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('v-select',{class:[
                        _vm.$v.proyeccion.operacion_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ],attrs:{"placeholder":"Operación","label":"Noperacion","options":_vm.listasForms.operaciones},on:{"input":function($event){return _vm.selectOperacion()}},model:{value:(_vm.operacion),callback:function ($$v) {_vm.operacion=$$v},expression:"operacion"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.operacion.Noperacion)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('v-select',{class:[
                        _vm.$v.proyeccion.producto_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ],attrs:{"placeholder":"Producto","label":"nombre","options":_vm.listasForms.productos},on:{"input":function($event){return _vm.selectProducto()}},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.producto.nombre)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('v-select',{class:[
                        _vm.$v.proyeccion.tipo_vehiculo_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ],attrs:{"placeholder":"Tipo Vehículo","label":"nombre","options":_vm.listasForms.tipo_vehiculos},on:{"input":function($event){return _vm.selectTipoVehiculo()}},model:{value:(_vm.tipo_vehiculo),callback:function ($$v) {_vm.tipo_vehiculo=$$v},expression:"tipo_vehiculo"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.tipo_vehiculo.nombre)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.tipo_tarifa),expression:"proyeccion.tipo_tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.tipo_tarifa.$invalid
                          ? 'is-invalid'
                          : 'is-valid',on:{"input":function($event){return _vm.buscarTarifaUni()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.proyeccion, "tipo_tarifa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipo_tarifas),function(tarifa){return _c('option',{key:tarifa.numeracion,domProps:{"value":tarifa.numeracion}},[_vm._v(" "+_vm._s(tarifa.descripcion)+" ")])})],2)]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.nTarifa)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('v-select',{class:[
                        _vm.$v.proyeccion.turno_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ],attrs:{"placeholder":"Turno","label":"nombre","options":_vm.listasForms.turnos},on:{"input":function($event){return _vm.selectTurno()}},model:{value:(_vm.turno),callback:function ($$v) {_vm.turno=$$v},expression:"turno"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.turno.nombre)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('v-select',{class:[
                        _vm.$v.proyeccion.empresa_id.$invalid
                          ? 'is-invalid'
                          : 'is-valid',
                        _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                        'form-control form-control-sm p-0',
                      ],attrs:{"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas},on:{"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.empresa.razon_social)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_vh),expression:"proyeccion.cant_vh"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_vh.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"number"},domProps:{"value":(_vm.proyeccion.cant_vh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_vh", $event.target.value)}}})]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.cant_vh)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.barriles_servicio),expression:"proyeccion.barriles_servicio"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.barriles_servicio.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.barriles_servicio)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "barriles_servicio", $event.target.value)},function($event){return _vm.calcGalones()}]}})]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.barriles_servicio)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.galones)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.galones)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_operativo),expression:"proyeccion.cant_operativo"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_operativo.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.cant_operativo)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_operativo", $event.target.value)},function($event){[_vm.totalesUni(), _vm.calcCant()]}]}})]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.cant_operativo)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.valor_operativo_format)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.valor_operativo_format)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.valor_fijo_format)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.valor_fijo_format)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2 &&
                        _vm.proyeccion.tipo_tarifa == 3
                    )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_disponible),expression:"proyeccion.cant_disponible"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_disponible.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.cant_disponible)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_disponible", $event.target.value)},function($event){[_vm.totalesUni(), _vm.calcCant()]}]}})]):_c('div',{staticClass:"text-center"},[(!proyec.cant_disponible)?_c('span',{staticClass:"badge bg-warning"},[_vm._v(" No cumple")]):_vm._e(),_vm._v(" "+_vm._s(proyec.cant_disponible)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.valor_disponible_format)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.valor_disponible_format)+" ")])]),_c('td',[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2 &&
                        _vm.proyeccion.tipo_tarifa == 2
                    )?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_km),expression:"proyeccion.cant_km"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_km.$invalid
                          ? 'is-invalid'
                          : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.cant_km)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_km", $event.target.value)},function($event){[_vm.totalesUni(), _vm.calcCant()]}]}})]):_c('div',{staticClass:"text-center"},[(!proyec.cant_km)?_c('span',{staticClass:"badge bg-warning"},[_vm._v(" No cumple")]):_vm._e(),_vm._v(" "+_vm._s(proyec.cant_km)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.valor_km_format)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.valor_km_format)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.total_cant)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.total_cant)+" ")])]),_c('td',{staticClass:"text-center"},[(
                      _vm.indice_actualizar_proyeccion === index &&
                        _vm.visualizar_proyeccion === 2
                    )?_c('div',[_vm._v(" "+_vm._s(_vm.proyeccion.total_format)+" ")]):_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(proyec.total_format)+" ")])])])}),(_vm.visualizar_proyeccion === 1)?_c('tr',[_c('td',[_c('div',{staticClass:"btn-group float-right"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.proyeccion.$invalid),expression:"!$v.proyeccion.$invalid"}],staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveProyeccion()}}},[_c('i',{staticClass:"fas fa-save"})]),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelProyeccion()}}},[_c('i',{staticClass:"fas fa-trash"})])])]),_c('td',[_c('v-select',{class:[
                      _vm.$v.proyeccion.operacion_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Operación","label":"Noperacion","options":_vm.listasForms.operaciones},on:{"input":function($event){return _vm.selectOperacion()}},model:{value:(_vm.operacion),callback:function ($$v) {_vm.operacion=$$v},expression:"operacion"}})],1),_c('td',[(
                      _vm.proyeccion.operacion_id &&
                        _vm.listasForms.productos &&
                        _vm.listasForms.productos.length > 0
                    )?_c('v-select',{class:[
                      _vm.$v.proyeccion.producto_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Producto","label":"nombre","options":_vm.listasForms.productos},on:{"input":function($event){return _vm.selectProducto()}},model:{value:(_vm.producto),callback:function ($$v) {_vm.producto=$$v},expression:"producto"}}):_c('span',{staticClass:"badge bg-danger"},[_vm._v("No hay Productos para la operación seleccionada.")])],1),_c('td',[_c('v-select',{class:[
                      _vm.$v.proyeccion.tipo_vehiculo_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Tipo Vehículo","label":"nombre","options":_vm.listasForms.tipo_vehiculos},on:{"input":function($event){return _vm.selectTipoVehiculo()}},model:{value:(_vm.tipo_vehiculo),callback:function ($$v) {_vm.tipo_vehiculo=$$v},expression:"tipo_vehiculo"}})],1),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.tipo_tarifa),expression:"proyeccion.tipo_tarifa"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.tipo_tarifa.$invalid
                        ? 'is-invalid'
                        : 'is-valid',on:{"input":function($event){return _vm.buscarTarifaUni()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.proyeccion, "tipo_tarifa", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipo_tarifas),function(tarifa){return _c('option',{key:tarifa.numeracion,domProps:{"value":tarifa.numeracion}},[_vm._v(" "+_vm._s(tarifa.descripcion)+" ")])})],2)]),_c('td',[_c('v-select',{class:[
                      _vm.$v.proyeccion.turno_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Turno","label":"nombre","options":_vm.listasForms.turnos},on:{"input":function($event){return _vm.selectTurno()}},model:{value:(_vm.turno),callback:function ($$v) {_vm.turno=$$v},expression:"turno"}})],1),_c('td',[_c('v-select',{class:[
                      _vm.$v.proyeccion.empresa_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                      'form-control form-control-sm p-0',
                    ],attrs:{"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas},on:{"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.empresa),callback:function ($$v) {_vm.empresa=$$v},expression:"empresa"}})],1),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_vh),expression:"proyeccion.cant_vh"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_vh.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"number"},domProps:{"value":(_vm.proyeccion.cant_vh)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_vh", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.barriles_servicio),expression:"proyeccion.barriles_servicio"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.barriles_servicio.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.barriles_servicio)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "barriles_servicio", $event.target.value)},function($event){return _vm.calcGalones()}]}})]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.galones)+" ")]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_operativo),expression:"proyeccion.cant_operativo"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_operativo.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.cant_operativo)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_operativo", $event.target.value)},function($event){[_vm.totalesUni(), _vm.calcCant()]}]}})]),(_vm.proyeccion.tipo_tarifa == 3)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.valor_operativo_format)+" ")]):_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge bg-warning"},[_vm._v("No Cumple")])]),(_vm.proyeccion.tipo_tarifa == 2)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.valor_fijo_format)+" ")]):_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge bg-warning"},[_vm._v("No Cumple")])]),_c('td',{staticClass:"text-center"},[(_vm.proyeccion.tipo_tarifa == 3)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_disponible),expression:"proyeccion.cant_disponible"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_operativo.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.cant_disponible)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_disponible", $event.target.value)},function($event){[_vm.totalesUni(), _vm.calcCant()]}]}}):_vm._e()]),(_vm.proyeccion.tipo_tarifa == 3)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.valor_disponible_format)+" ")]):_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge bg-warning"},[_vm._v("No Cumple ")])]),_c('td',[(_vm.proyeccion.tipo_tarifa == 2)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.proyeccion.cant_km),expression:"proyeccion.cant_km"}],staticClass:"form-control form-control-sm",class:_vm.$v.proyeccion.cant_km.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"type":"text"},domProps:{"value":(_vm.proyeccion.cant_km)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.proyeccion, "cant_km", $event.target.value)},function($event){[_vm.totalesUni(), _vm.calcCant()]}]}}):_vm._e()]),(_vm.proyeccion.tipo_tarifa == 2)?_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.valor_km_format)+" ")]):_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"badge bg-warning"},[_vm._v(" No Cumple")])]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.total_cant)+" ")]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.proyeccion.total_format)+" ")])]):_vm._e()],2)])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header pt-1 pb-1 bg-frontera text-white"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-10"},[_c('h5',{staticClass:"mb-0 p-2"},[_vm._v(" Detalle Proyección Transporte Internos Fluidos ")])]),_c('div',{staticClass:"col-md-2 pt-3 pb-3"},[_c('div',{staticClass:"btn-group float-right"},[_c('button',{staticClass:"btn btn-tool",attrs:{"type":"button","data-card-widget":"collapse","data-toggle":"tooltip","title":"Collapse"}},[_c('i',{staticClass:"fas fa-minus"})])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-dark"},[_c('tr',{staticClass:"text-center"},[_c('th',{attrs:{"rowspan":"2"}},[_vm._v("Acción")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Operación ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Producto ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Tipo Vehículo ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Tipo Tarifa ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Turno ")]),_c('th',{staticStyle:{"min-width":"15em"},attrs:{"rowspan":"2"}},[_vm._v(" Empresa ")]),_c('th',{staticStyle:{"min-width":"10em"},attrs:{"rowspan":"2"}},[_vm._v(" Cant Vehículos ")]),_c('th',{staticStyle:{"min-width":"10em"},attrs:{"rowspan":"2"}},[_vm._v(" Servicio T.O.V "),_c('small',[_vm._v("(BLS)")])]),_c('th',{staticStyle:{"min-width":"10em"},attrs:{"rowspan":"2"}},[_vm._v(" Galones ")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("Operativo")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Disponibilidad")]),_c('th',{attrs:{"colspan":"2"}},[_vm._v("Kilómetros")]),_c('th',{attrs:{"colspan":"3"}},[_vm._v("Total")])]),_c('tr',{staticClass:"text-center"},[_c('th',{staticStyle:{"min-width":"10em"}},[_vm._v("Cantidad")]),_c('th',{staticStyle:{"min-width":"10em"}},[_vm._v("Valor")]),_c('th',{staticStyle:{"min-width":"10em"}},[_vm._v(" Costo Tarifa Fija ")]),_c('th',{staticStyle:{"min-width":"10em"}},[_vm._v("Cantidad")]),_c('th',{staticStyle:{"min-width":"10em"}},[_vm._v("Valor")]),_c('th',{staticStyle:{"min-width":"8em"}},[_vm._v("Cantidad")]),_c('th',{staticStyle:{"min-width":"10em"}},[_vm._v(" Costo Tarifa Variable ")]),_c('th',{staticStyle:{"min-width":"8em"}},[_vm._v("Cantidad")]),_c('th',{staticStyle:{"min-width":"8em"}},[_vm._v("Valor")])])])
}]

export { render, staticRenderFns }